import { tw } from '@/utils/tw';
import DialogTheme from 'base/components/Dialog/theme';

const { CloseIcon, DialogIcon, Button } = DialogTheme;

const CloseButton = tw.theme({
  extend: DialogTheme.CloseButton,
  base: ['absolute', 'right-3', 'top-3', 'max-h-6', 'w-6', '!px-0'],
});

const Dialog = tw.theme({
  extend: DialogTheme,
  slots: {
    base: ['backdrop:bg-black/60', 'bg-linen-200'],
    headline: ['text-headline-lg'],
    content: ['text-content-xs', 'md:text-content-sm'],
    buttonGroup: ['[&>button]:max-w-none'],
  },
});

export default Object.assign(Dialog, { CloseIcon, DialogIcon, Button, CloseButton });
