import { ArticlePaywall } from '@/components/standalone/ArticlePaywall';
import { useStable } from '@/hooks/useStable';
import { mergeProps } from '@/utils/merge';
import { StandaloneContentLock } from 'base/components/ContentLock';
import { useMemo } from 'react';

export const FeminaStandaloneContentLock: typeof StandaloneContentLock = (props) => {
  const headline = useMemo(
    () => (
      <>
        Vill du läsa hela artikeln?
        <br />
        Skapa ett konto på Femina – helt gratis!
      </>
    ),
    [],
  );

  const content = useStable({
    login: {
      caption: 'Medlemmar får mer – spara artiklar och obegränsad läsning.',
      headline,
    },
    purchase: <ArticlePaywall />,
  });

  return <StandaloneContentLock {...mergeProps({ content }, props)} />;
};
