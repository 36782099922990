import { Icon } from '@/components/Icon';
import { Link } from '@/components/Link';
import FeminaPlusLogo from '@/femina/public/image/femina-plus-logo.svg';
import { StandaloneComponent } from '@/types/component';
import { ArticlePaywallProps, StandaloneArticlePaywall } from 'base/components/standalone/ArticlePaywall';
import KlarnaBadgeLogo from 'base/public/image/klarna-badge.png';

import NextImage from 'next/image';

export const FeminaStandaloneArticlePaywall: StandaloneComponent<ArticlePaywallProps> = ({ ...props }) => {
  return (
    <StandaloneArticlePaywall
      logo={<FeminaPlusLogo />}
      headline="Vill du läsa hela artikeln?"
      description="Starta din prenumeration av Femina Plus redan i dag, och få allt detta för bara 29 kr i månaden:"
      dialog={{
        headline: 'Tack för ditt köp',
        closeButton: 'close',
        content: (
          <>
            <p className="mb-4 text-content-sm md:mb-5 md:text-content-md">Välkommen till Femina Plus</p>
            <p className="mb-3 text-left text-content-sm md:mb-8 md:text-content-md">
              Det enda du behöver göra nu för att få tillgång till ditt exklusiva innehåll är att:
            </p>
            <ol className="ml-x list-decimal md:ml-6">
              <li className="mb-3 text-left text-content-heavy-sm md:mb-7 md:text-content-heavy-md">
                Gå till din mejl (samma som du angav i förra steget)
              </li>
              <li className="mb-3 text-left text-content-heavy-sm md:mb-7 md:text-content-heavy-md">
                Klicka på länken i mejlet för att bekräfta ditt konto
              </li>
              <li className="mb-8 text-left text-content-heavy-sm md:text-content-heavy-md">
                Sen kan du enkelt komma tillbaka hit, ladda om sidan och ta del av allt spännande innehåll som är
                exklusivt bara för dig som Plus-prenumerant.
              </li>
            </ol>
            <p className="[&>a:hover]:underline [&>a]:text-summer-red-600">
              Om du har frågor eller behöver hjälp med din prenumeration, kontakta oss här på{' '}
              <Link href="mailto:femina@kundtjanst.se" content="femina@kundtjanst.se" />
            </p>
          </>
        ),
      }}
      form={{
        button: 'Fortsätt till betalning',
        features: [
          <>
            <Icon name="check" options={{ className: 'mt-0.5 flex-shrink-0' }} />
            Obegränsad tillgång till alla artiklar, spännande livshistorier och djupgående intervjuer
          </>,
          <>
            <Icon name="check" options={{ className: 'mt-0.5 flex-shrink-0' }} />
            Upp till 30 exklusiva artiklar per månad, bara för dig som Plus kund
          </>,
          <>
            <Icon name="check" options={{ className: 'mt-0.5 flex-shrink-0' }} />
            Exklusivt nyhetsbrev med våra bästa lästips ingår
          </>,
        ],
        caption: (
          <>
            Redan prenumerant? <br className="md:hidden" />
            <Link
              target="_blank"
              href="https://login.allermedia.se/?return=https%3A%2F%2Fwww.femina.se%2F"
              content="Logga in på ditt konto här"
            />
          </>
        ),
        policy: (
          <>
            Genom köp av prenumeration kommer ett konto skapas till dig. Dina uppgifter kommer hanteras enligt{' '}
            <Link
              content={'Aller Medias personuppgiftspolicy'}
              target="_blank"
              href="https://www.aller.se/integritetspolicy"
            />
            . Ett konto hos oss kostar ingenting.
          </>
        ),
        provider: (
          <>
            Betala med
            <NextImage className="h-13 w-20" src={KlarnaBadgeLogo} alt="Klarna logo" />
          </>
        ),
      }}
      {...props}
    />
  );
};
