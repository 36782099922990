import { tw } from '@/utils/tw';
import { default as BaseVideoReelsTheme } from 'base/components/VideoReels/theme';

const VideoReels = tw.theme({
  extend: BaseVideoReelsTheme,
  slots: {
    base: 'max-w-full sm:max-w-full md:max-w-full lg:max-w-full',
  },
});

export default VideoReels;
