import { tw } from '@/utils/tw';
import BaseContentLockTheme from 'base/components/ContentLock/theme';

const Button = tw.theme({
  extend: BaseContentLockTheme.Button,
  variants: {
    size: {
      large: 'my-0',
    },
  },
});

const ContentLockTheme = tw.theme({
  extend: BaseContentLockTheme,
  slots: {
    login: [
      'py-6',
      'md:py-12',
      'bg-linen-100',
      'gap-y-3',
      'flex',
      'flex-col',
      'items-center',
      'text-center',
      'not-prose',
    ],
    loginCaption: ['text-content-sm', 'md:text-content-md', 'pb-6', 'max-w-[460px]'],
    loginFooter: ['underline', 'hover:no-underline', 'text-content-sm', 'text-summer-red-600'],
    loginHeadline: ['text-headline-md', 'md:text-headline-xl'],
  },
});

export default Object.assign(ContentLockTheme, { Button });
