import { tw } from '@/utils/tw';
import BaseExpertSliderTheme from 'base/components/ExpertSlider/theme';

const Slider = tw.theme({
  extend: BaseExpertSliderTheme.Slider,
});

const Image = tw.theme({
  extend: BaseExpertSliderTheme.Image,
  slots: {
    base: ['bg-linen-400', 'data-[fill]:!aspect-[0.72/1]', 'sm:pt-4', 'pt-2'],
  },
});

const ExpertSlider = tw.theme({
  extend: BaseExpertSliderTheme,
  slots: {
    headline: ['sm:text-headline-lg', 'text-headline-md', 'normal-case'],
    caption: ['uppercase', 'text-category-sm', 'sm:text-category-lg', 'my-1.5', 'block'],
    description: ['text-headline-sm', 'sm:text-headline-md'],
  },
});

export default Object.assign(ExpertSlider, { Image, Slider });
