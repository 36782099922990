import { DialogTheme } from '@/components/Dialog/theme';
import { KlarnaPaymentTheme } from '@/components/KlarnaPayment/theme';
import { tw } from '@/utils/tw';
import BaseArticlePaywallTheme from 'base/components/standalone/ArticlePaywall/theme';

const ArticlePaywallFormTheme = tw.theme({
  extend: BaseArticlePaywallTheme,
  slots: {
    base: [
      'bg-linen-200',
      'md:max-w-article',
      'md:mx-auto',
      'md:pt-11',
      'pb-6',
      'pt-8.5',
      'px-4',
      'relative',
      'rounded',
      'sm:px-7',
    ],
    description: ['text-content-sm', 'text-center', 'mb-3'],
    headline: ['text-headline-lg', 'sm:text-headline-xl', 'text-center', 'mb-3'],
    logo: [
      '-translate-x-1/2',
      '-translate-y-1/2',
      'absolute',
      'h-11',
      'left-1/2',
      'md:h-13.5',
      'md:w-13.5',
      'top-0',
      'w-11',
    ],
  },
});

export default Object.assign(ArticlePaywallFormTheme, {
  Dialog: DialogTheme,
  Form: BaseArticlePaywallTheme.Form,
  KlarnaPayment: KlarnaPaymentTheme,
});
