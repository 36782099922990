import { tw } from '@/utils/tw';
import BaseAuthorsListTheme from 'base/components/AuthorsList/theme';

const ListItemImage = tw.theme({
  extend: BaseAuthorsListTheme.ListItemImage,
  slots: {
    base: ['rounded-none'],
  },
});

const AuthorsListTheme = tw.theme({
  extend: BaseAuthorsListTheme,
  slots: {
    base: ['bg-linen-100', 'px-10', 'py-4', 'gap-y-0'],
    headline: ['text-headline', 'my-6'],
    description: ['hidden'],
    list: ['grid', 'grid-cols-1', 'sm:grid-cols-2', 'md:grid-cols-3', 'gap-6', 'mb-6'],
    listItem: ['w-full', 'sm:w-88'],
    listItemTitle: ['text-headline-sm'],
  },
  variants: {
    colors: {
      default: { listItem: ['bg-white', 'hover:bg-linen-200', 'active:bg-gray-100'] },
    },
  },
});

export default Object.assign(AuthorsListTheme, {
  ListItemLink: BaseAuthorsListTheme.ListItemLink,
  ListItemImage,
  ListItemIcon: BaseAuthorsListTheme.ListItemIcon,
});
