import { tw } from '@/utils/tw';
import CancelSubscriptionFormThemeBase from 'base/components/CancelSubscription/SubscriptionCancelled/theme';

const CancelSubscriptionFormTheme = tw.theme({
  extend: CancelSubscriptionFormThemeBase,
  slots: {
    base: ['md:max-w-article', 'bg-linen-200'],
    headline: ['text-headline-sm', 'md:text-headline-md'],
    description: ['text-content-sm'],
    form: [],
    formHeadline: ['text-content-heavy-sm'],
    radioGroup: [],
  },
});

export default Object.assign(CancelSubscriptionFormTheme, {
  RadioButton: CancelSubscriptionFormThemeBase.RadioButton,
  TextArea: CancelSubscriptionFormThemeBase.TextArea,
  Button: CancelSubscriptionFormThemeBase.Button,
});
