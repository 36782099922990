import { Link } from '@/components/Link';
import { useStableProps } from '@/hooks/useStable';
import { mergeProps } from '@/utils/merge';
import {
  getPaymentsItems,
  GetPaymentsResponse,
  StandaloneSubscriptionDetails,
  StandaloneSubscriptionDetailsProps,
} from 'base/components/SubscriptionDetails';
import { StandaloneSubscriptionDetailsDigital } from 'base/components/SubscriptionDetails/Digital';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

export const FeminaStandaloneSubscriptionDetails: typeof StandaloneSubscriptionDetails = (props) => {
  const [paymentHistory, setPaymentHistory] = useState<GetPaymentsResponse | undefined>();

  useEffectOnce(() => {
    async function fetchData() {
      const data = await getPaymentsItems();
      setPaymentHistory(data);
    }
    fetchData();
  });

  const stableProps: StandaloneSubscriptionDetailsProps = useStableProps({
    digitalSubscriptionAccordionProps: {
      title: (
        <>
          Hantera din <strong>digitala prenumeration</strong>
        </>
      ),
      content: (
        <StandaloneSubscriptionDetailsDigital
          headline="Femina plus"
          description="Tillgång till alla digitala artiklar på femina.se"
          detailsList={[
            <>
              Påbörjad: <b>{paymentHistory?.startDate}</b>
            </>,
            <>
              Avslutas: <b>{paymentHistory?.endDate}</b>
            </>,
            <>
              Status: <b>Betalas med klarna</b>
            </>,
          ]}
          buttonLabels={{ paymentHistory: 'Betalningshistorik' }}
          contactInfo={
            <>
              Om du har några frågor, kontakta oss på{' '}
              <Link content="femina@kundtjanst.se" href="mailto:femina@kundtjanst.se" />
            </>
          }
          history={{
            headline: 'Din betalningshistorik',
            items: paymentHistory?.payments?.map(({ date, description, amount, currency }) => ({
              date,
              description,
              price: `${amount / 100} ${currency}`,
            })),
          }}
        />
      ),
    },
  });
  return <StandaloneSubscriptionDetails {...mergeProps(stableProps, props)} />;
};
