import { ExpertSliderProps } from '@/components/ExpertSlider';
import AinoOxblodImage from '@/femina/public/image/experts/aino-oxblod.webp';
import JessicaBlockstromImage from '@/femina/public/image/experts/jessica-blockstrom.webp';
import MalinRoosImage from '@/femina/public/image/experts/malin-roos.webp';
import { mergeProps } from '@/utils/merge';
import { StandaloneExpertSlider } from 'base/components/ExpertSlider';

const slides: ExpertSliderProps['slides'] = [
  {
    image: { src: MalinRoosImage, alt: 'Chefredaktör' },
    caption: 'Malin Roos, chefredaktör',
    description: 'Malin Roos är Feminas chefredaktör och intervjuproffs, hennes reportage berör alltid på djupet.',
    href: '/vara-skribenter/malin_roos',
  },
  {
    image: { src: AinoOxblodImage, alt: 'Nöjesreporter' },
    caption: 'Aino Oxblod, nöjesreporter',
    description:
      'Vassa kändisreportern Aino Oxblod möter kändisar varje vecka för att ge läsarna de allra bästa nöjesnyheterna.',
    href: '/vara-skribenter/aino_oxblod',
  },

  {
    image: { src: JessicaBlockstromImage, alt: 'Skönhetsredaktör' },
    caption: 'Jessica Blockström, skönhetsredaktör',
    description:
      'Skönhetsredaktören Jessica Blockström har lång erfarenhet av allt inom beauty – från smink till hår- och hudvård.',
    href: '/vara-skribenter/jessica_blockstrom_hember',
  },
];

export const FeminaStandaloneExpertSlider: typeof StandaloneExpertSlider = (props) => {
  return <StandaloneExpertSlider {...mergeProps(props, { headline: 'Våra experter', slides })} />;
};
