import { tw } from '@/utils/tw';
import DigitalSubscriptionHistoryItemThemeBase from 'base/components/standalone/SubscriptionDetails/Digital/History/Item/theme';

const InvoiceLink = tw.theme({
  extend: DigitalSubscriptionHistoryItemThemeBase.InvoiceLink,
  slots: {
    base: ['text-content-heavy-xs', 'text-summer-red-700'],
  },
});

const DigitalSubscriptionHistoryItemTheme = tw.theme({
  extend: DigitalSubscriptionHistoryItemThemeBase,
  slots: {
    base: [
      '[&:not(:last-child)]:border-b',
      '[&:not(:last-child)]:border-linen-400',
      '[&:not(:last-child)]:pb-3',
      '[&:not(:last-child)]:mb-3',
    ],
    date: ['text-content-xs'],
    description: ['text-content-xs'],
    price: ['text-content-xs'],
  },
});

export default Object.assign(DigitalSubscriptionHistoryItemTheme, {
  InvoiceLink,
});
