import { tw } from '@/utils/tw';
import SubscriptionDetailsDigitalHistoryThemeBase from 'base/components/standalone/SubscriptionDetails/Digital/History/theme';

const SubscriptionDetailsDigitalHistoryTheme = tw.theme({
  extend: SubscriptionDetailsDigitalHistoryThemeBase,
  slots: {
    actionsGroup: ['flex', 'flex-col', 'max-w-80', 'mx-auto', 'gap-4', 'items-center'],
    base: ['border-t-2', 'border-black', 'mt-6', 'pt-6'],
    headline: ['text-content-heavy-xs', 'md:text-content-heavy-sm'],
    list: ['pt-3.5', 'pb-6'],
  },
});

export default Object.assign(SubscriptionDetailsDigitalHistoryTheme, {
  Button: SubscriptionDetailsDigitalHistoryThemeBase.Button,
  Item: SubscriptionDetailsDigitalHistoryThemeBase.Item,
  Link: SubscriptionDetailsDigitalHistoryThemeBase.Link,
});
