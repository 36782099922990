import { tw } from '@/utils/tw';
import CancelSubscriptionThemeBase from 'base/components/CancelSubscription/theme';

const Link = tw.theme({
  extend: CancelSubscriptionThemeBase.Link,
  slots: {
    base: ['text-center', 'text-summer-red-700'],
  },
});

const CancelSubscriptionTheme = tw.theme({
  extend: CancelSubscriptionThemeBase,
  slots: {
    base: ['md:max-w-article', 'bg-linen-200', 'shadow'],
    headline: ['text-headline-sm', 'md:text-headline-md'],
    description: ['text-content-sm'],
  },
});

export default Object.assign(CancelSubscriptionTheme, {
  Button: CancelSubscriptionThemeBase.Button,
  Link,
  Form: CancelSubscriptionThemeBase.Form,
});
